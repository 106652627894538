import React, { useState, useEffect } from "react"
// import React from "react"
import { Link } from "gatsby"

import algoliasearch from "algoliasearch/lite"
// import gql from 'graphql-tag';

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"

import CommunitiesTable from "../components/community/communitiestable"
import TopicCard from "../components/topic/topiccard"
import TopicList from "../components/topic/topiclist"
import PlatformCard from "../components/platform/platformcard"
import withLocation from "../components/common/withlocation"
import Image from "../components/common/image"
import Adsense from "../components/promotions/adsense"

import { trackEvent } from "../utils/tracking"


const SearchPage = ({ queryParams }) => {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [topics, setTopics] = useState(null);
  const [communities, setCommunities] = useState(null);
  const [platforms, setPlatforms] = useState(null);

  const searchTerm = queryParams.keyword && decodeURIComponent(queryParams.keyword);

  // algolia
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );
  const index = searchClient.initIndex('Pages');
  
  // perform search just once (for the search term)
  useEffect(() => {
    index.search(searchTerm, {}) // empty dict is request options
      .then(({ hits }) => {
        setResults(hits)
        const topicHits = hits.filter(h => h.objectID.startsWith('topic-'));
        setTopics(topicHits);
        const communityHits = hits.filter(h => h.objectID.startsWith('community-'));
        setCommunities(communityHits);
        setPlatforms(hits.filter(h => h.objectID.startsWith('platform-')));
        setLoading(false)
        // console.log(topicHits)

        // send search event
        trackEvent('Search', {
          'event_label': searchTerm,
          'term': searchTerm,
          'hits': hits.length,
          'topicHits': topicHits.length,
          'communityHits': communityHits.length,
        })
      });
  }, [searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  // initial render state
  if (!searchTerm || loading){
    return (
      <Layout pageTitle={`Searching...`}>
        <SEO title="Search | Hive Index" dontIndex={true} />
        <div className="h-96"></div>
      </Layout>
    )
  }

  const showPromotionSidebar = false; // don't show ads here (don't really perform well, and we're now sending new community owners here in the email)

  return (
  	<Layout pageTitle={`Search results for "${searchTerm}"`}
      //subtitle={'Powered by Algolia'}
      noBackground={true}
    >
    	<SEO title="Search | Hive Index" dontIndex={true} />

      {/* Main 3 column grid */}
      <div className={`grid grid-cols-1 gap-4 items-start ${showPromotionSidebar ? 'lg:grid-cols-4' : 'lg:grid-cols-3'} lg:gap-8`}>
        {/* Left column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-3">

          {/* main panel */}
          <div className="bg-white rounded-lg shadow overflow-hidden p-4 sm:p-6">
            {topics && topics.length > 0 ? (
              <div className="mb-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex items-center justify-between">
                    <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Topics</h2>
                  </div>
                </div>

                <div className="mt-2">
                  <TopicList topics={topics} />
                </div>
              </div>
            ) : ''}

            {platforms && platforms.length > 0 ? (
              <div className="mb-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex items-center justify-between">
                    <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Platforms</h2>
                  </div>
                </div>

                <ul className={`mt-2 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8`}>
                  {platforms.map(p=>(
                    <PlatformCard key={p.slug} platform={p} />
                  ))}
                </ul>
              </div>
            ) : ''}

            {communities && communities.length > 0 ? (
              <div className="mb-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex items-center justify-between">
                    <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
                      <div>Communities</div>
                      <span className="ml-2 font-semibold opacity-50 text-xs">{communities.length}</span>
                    </h2>
                  </div>
                </div>

                <div className="mt-2">
                  <CommunitiesTable communities={communities} showTopics={true} showPlatform={true}
                    linkModal={false} // breaks on going back. need to fix and re-enable
                  />
                </div>
              </div>
            ) : ''}

            {results && results.length === 0 ? (
              <div className="mb-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex items-center justify-between">
                    <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Oh no, I couldn't find anything...</h2>
                  </div>
                </div>

                <div className="mt-2 text-center">
                  <Image fileName="search.png" alt="Search image" className="mx-auto" style={{width: '400px'}}/>   
                  <div className="text-center mt-4">
                    Please check out the <Link to="/topics/" className="underline">full list of topics</Link> to find something you're interested in.<br/>
                    If you think this website is missing a good online community or topic, please <Link to="/submit/" className="underline">submit it</Link>!<br/><br/><br/>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mb-6 text-gray-500">
                <div className="p-6 pb-0 mx-auto text-center">
                  <div>Didn't find what you're looking for?</div>
                  <div className="mx-auto text-gray-600 font-semibold text-xs mt-2">
                    <Link to="/suggest/" className="opacity-75 hover:opacity-100 cursor-pointer">Suggest a Topic</Link>
                    <span> or </span>
                    <Link to="/submit/" className="opacity-75 hover:opacity-100 cursor-pointer">Add Community</Link>
                    <span> or </span>
                    <Link to="/topics/" className="opacity-75 hover:opacity-100 cursor-pointer">Browse Topics →</Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right column */}
        {showPromotionSidebar ? (
          <div className="grid grid-cols-1 gap-4">

            <div className="rounded-lg bg-white overflow-hidden shadow p-6">
              <Adsense id="3156748745" type="search" />
            </div>
            
          </div>
        ) : ''}
      </div>
  	</Layout>
  )
}

export default withLocation(SearchPage);
