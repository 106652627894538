import React, { useEffect } from "react"

// import { trackEvent } from "../../utils/tracking"

// kultee jobs if they exist
const Adsense = ({id, type}) => {
  return ''; // not doing ads right now

  // useEffect(() => {
  //   try {
  //     const adsbygoogle = typeof window !== "undefined" && window.adsbygoogle || []
  //     adsbygoogle.push({})
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }, []); // launch the ad slot

  // return (
  //   <ins className="adsbygoogle block"
  //    data-ad-client="ca-pub-2817348671051026"
  //    data-ad-slot={id}
  //    data-ad-format="auto"
  //    onClick={() => console.log("click", id)}
  //    data-full-width-responsive="true"></ins>
  // )
}

export default Adsense;

